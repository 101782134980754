// extracted by mini-css-extract-plugin
export var root = "index-module--root--ny_aw";
export var wrapper = "index-module--wrapper--3IIJ8";
export var image = "index-module--image--1oQVM";
export var overlay = "index-module--overlay--2UsFb";
export var article = "index-module--article--1lMkF";
export var meta = "index-module--meta--2HRUa";
export var date = "index-module--date--1NN1_";
export var day = "index-module--day--3tENK";
export var month = "index-module--month--3ygUF";
export var excerpt = "index-module--excerpt--3SRc9";
export var _small = "index-module--_small--3p-RA";