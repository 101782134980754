// extracted by mini-css-extract-plugin
export var root = "index-module--root--2ITKk";
export var wrapper = "index-module--wrapper--1UNlU";
export var calendar = "index-module--calendar--angBH";
export var month = "index-module--month--3XvS-";
export var nav = "index-module--nav--DC7rw";
export var currentMonth = "index-module--currentMonth--1OPSO";
export var weekdays = "index-module--weekdays--10y2a";
export var weekday = "index-module--weekday--1ba3r";
export var weekend = "index-module--weekend--_fEE0";
export var days = "index-module--days--4CU7z";
export var day = "index-module--day--2euLD";
export var selected = "index-module--selected--m2En5";
export var offset = "index-module--offset--2P1ND";
export var events = "index-module--events--_ZT1E";
export var event = "index-module--event--2irW9";
export var eventDate = "index-module--eventDate--3lMCR";
export var eventText = "index-module--eventText--JvwyM";