export default [
  {
    text: "Приобретение биржевых облигаций серии БО-001-03 по требованию владельцев",
    date: new Date("2021-12-27T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-02",
    date: new Date("2021-12-21T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-06",
    date: new Date("2021-12-21T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-03",
    date: new Date("2021-12-13T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-05",
    date: new Date("2021-12-08T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-04",
    date: new Date("2021-12-06T00:00:00")
  },
  {
    text: "Приобретение коммерческих облигаций серии КО-СЛ-004 по требованию владельцев",
    date: new Date("2021-11-11T00:00:00")
  },
  {
    text: "Выплата купонного дохода по коммерческим облигациям серии КО-СЛ-004",
    date: new Date("2021-11-08T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-01",
    date: new Date("2021-10-19T00:00:00")
  },
  {
    text: "Выплата купонного дохода по коммерческим облигациям серии КО-СЛ-007",
    date: new Date("2021-10-06T00:00:00")
  },
  {
    text: "Приобретение биржевых облигаций серии БО-001-02 по требованию владельцев",
    date: new Date("2021-10-05T00:00:00")
  },
  {
    text: "Приобретение биржевых облигаций серии БО-001-05 по требованию владельцев",
    date: new Date("2021-09-22T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-06",
    date: new Date("2021-09-21T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-02",
    date: new Date("2021-09-21T00:00:00")
  },
  {
    text: "Приобретение коммерческих облигаций серии КО-СЛ-003 по требованию владель",
    date: new Date("2021-09-14T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-03",
    date: new Date("2021-09-13T00:00:00")
  },
  {
    text: "Выплата купонного дохода по коммерческим облигациям серии КО-СЛ-003",
    date: new Date("2021-09-09T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-05",
    date: new Date("2021-09-08T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-04",
    date: new Date("2021-09-06T00:00:00")
  },
  {
    text: "Приобретение биржевых облигаций серии БО-001-01 по требованию владельцев",
    date: new Date("2021-08-03T00:00:00")
  },
  {
    text: "Выплата купонного дохода по коммерческим облигациям серии КО-СЛ-006",
    date: new Date("2021-07-27T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-01",
    date: new Date("2021-07-20T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-06",
    date: new Date("2021-06-22T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-02",
    date: new Date("2021-06-22T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-03",
    date: new Date("2021-06-14T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-05",
    date: new Date("2021-06-09T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-04",
    date: new Date("2021-06-07T00:00:00")
  },
  {
    text: "Выплата купонного дохода по коммерческим облигациям серии КО-СЛ-004",
    date: new Date("2021-05-10T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-01",
    date: new Date("2021-04-20T00:00:00")
  },
  {
    text: "Приобретение коммерческих облигаций серии КО-СЛ-007 по требованию владельцев",
    date: new Date("2021-04-12T00:00:00")
  },
  {
    text: "Выплата купонного дохода по коммерческим облигациям серии КО-СЛ-007",
    date: new Date("2021-04-07T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-06",
    date: new Date("2021-03-23T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-02",
    date: new Date("2021-03-23T00:00:00")
  },
  {
    text: "Приобретение биржевых облигаций серии БО-001-04 по требованию владельцев",
    date: new Date("2021-03-22T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-03",
    date: new Date("2021-03-15T00:00:00")
  },
  {
    text: "Выплата купонного дохода по коммерческим облигациям серии КО-СЛ-003",
    date: new Date("2021-03-11T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-05",
    date: new Date("2021-03-10T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-04",
    date: new Date("2021-03-08T00:00:00")
  },
  {
    text: "Приобретение коммерческих облигаций серии КО-СЛ-006 по требованию владельцев",
    date: new Date("2021-01-29T00:00:00")
  },
  {
    text: "Выплата купонного дохода по коммерческим облигациям серии КО-СЛ-006",
    date: new Date("2021-01-26T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-01",
    date: new Date("2021-01-19T00:00:00")
  },
  {
    text: "Выплата купонного дохода и амортизации по коммерческим облигациям серии КО-09",
    date: new Date("2022-01-04T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-01",
    date: new Date("2022-01-18T00:00:00")
  },
  {
    text: "Выплата купонного дохода по коммерческим облигациям серии КО-СЛ-006",
    date: new Date("2022-01-25T00:00:00")
  },
  {
    text: "Приобретение коммерческих облигаций серии КО-СЛ-006 по требованию владельцев",
    date: new Date("2022-01-28T00:00:00")
  },
  {
    text: "Выплата купонного дохода и амортизации по коммерческим облигациям серии КО-09",
    date: new Date("2022-02-03T00:00:00")
  },
  {
    text: "Выплата купонного дохода и амортизации по коммерческим облигациям серии КО-09",
    date: new Date("2022-03-05T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-04",
    date: new Date("2022-03-07T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-05",
    date: new Date("2022-03-09T00:00:00")
  },
  {
    text: "Выплата купонного дохода по коммерческим облигациям серии КО-СЛ-003",
    date: new Date("2022-03-10T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-03",
    date: new Date("2022-03-14T00:00:00")
  },
  {
    text: "Приобретение биржевых облигаций серии БО-001-04 по требованию владельцев",
    date: new Date("2022-03-21T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-02",
    date: new Date("2022-03-22T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-06",
    date: new Date("2022-03-22T00:00:00")
  },
  {
    text: "Выплата купонного дохода по облигациям, предназначенным для квалифицированных инвесторов серии 01",
    date: new Date("2022-03-29T00:00:00")
  },
  {
    text: "Выплата купонного дохода и амортизации по коммерческим облигациям серии КО-09",
    date: new Date("2022-04-04T00:00:00")
  },
  {
    text: "Выплата купонного дохода по коммерческим облигациям серии КО-СЛ-007",
    date: new Date("2022-04-06T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-01",
    date: new Date("2022-04-19T00:00:00")
  },
  {
    text: "Выплата купонного дохода и амортизации по коммерческим облигациям серии КО-09",
    date: new Date("2022-05-04T00:00:00")
  },
  {
    text: "Выплата купонного дохода по коммерческим облигациям серии КО-СЛ-004",
    date: new Date("2022-05-09T00:00:00")
  },
  {
    text: "Выплата купонного дохода и амортизации по коммерческим облигациям серии КО-09",
    date: new Date("2022-06-03T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-04",
    date: new Date("2022-06-06T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-05",
    date: new Date("2022-06-08T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-03",
    date: new Date("2022-06-13T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-02",
    date: new Date("2022-06-21T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-06",
    date: new Date("2022-06-21T00:00:00")
  },
  {
    text: "Выплата купонного дохода по облигациям, предназначенным для квалифицированных инвесторов серии 01",
    date: new Date("2022-06-28T00:00:00")
  },
  {
    text: "Выплата купонного дохода и амортизации по коммерческим облигациям серии КО-09",
    date: new Date("2022-07-03T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-01",
    date: new Date("2022-07-19T00:00:00")
  },
  {
    text: "Выплата купонного дохода по коммерческим облигациям серии КО-СЛ-006",
    date: new Date("2022-07-26T00:00:00")
  },
  {
    text: "Выплата купонного дохода и амортизации по коммерческим облигациям серии КО-09",
    date: new Date("2022-08-02T00:00:00")
  },
  {
    text: "Выплата купонного дохода и амортизации по коммерческим облигациям серии КО-09",
    date: new Date("2022-09-01T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-04",
    date: new Date("2022-09-05T00:00:00")
  },
  {
    text: "Погашение выпуска + Выплата купонного дохода по биржевым облигациям серии БО-001-05",
    date: new Date("2022-09-07T00:00:00")
  },
  {
    text: "Погашение выпуска + Выплата купонного дохода по коммерческим облигациям серии КО-СЛ-003",
    date: new Date("2022-09-08T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-03",
    date: new Date("2022-09-12T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-02",
    date: new Date("2022-09-20T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-06",
    date: new Date("2022-09-20T00:00:00")
  },
  {
    text: "Выплата купонного дохода и амортизации по облигациям, предназначенным для квалифицированных инвесторов серии 01",
    date: new Date("2022-09-27T00:00:00")
  },
  {
    text: "Выплата купонного дохода и амортизации по коммерческим облигациям серии КО-09",
    date: new Date("2022-10-01T00:00:00")
  },
  {
    text: "Выплата купонного дохода по коммерческим облигациям серии КО-СЛ-007",
    date: new Date("2022-10-05T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-01",
    date: new Date("2022-10-18T00:00:00")
  },
  {
    text: "Выплата купонного дохода и амортизации по коммерческим облигациям серии КО-09",
    date: new Date("2022-10-31T00:00:00")
  },
  {
    text: "Погашение выпуска+ Выплата купонного дохода по коммерческим облигациям серии КО-СЛ-004",
    date: new Date("2022-11-07T00:00:00")
  },
  {
    text: "Выплата купонного дохода и амортизации по коммерческим облигациям серии КО-09",
    date: new Date("2022-11-30T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-04",
    date: new Date("2022-12-05T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-03",
    date: new Date("2022-12-12T00:00:00")
  },
  {
    text: "Выплата купонного дохода по биржевым облигациям серии БО-001-02",
    date: new Date("2022-12-20T00:00:00")
  },
  {
    text: "Погашение выпуска+Выплата купонного дохода по биржевым облигациям серии БО-001-06",
    date: new Date("2022-12-20T00:00:00")
  },
  {
    text: "Выплата купонного дохода и амортизации по облигациям, предназначенным для квалифицированных инвесторов серии 01",
    date: new Date("2022-12-27T00:00:00")
  },
  {
    text: "Выплата купонного дохода и амортизации по коммерческим облигациям серии КО-09",
    date: new Date("2022-12-30T00:00:00")
  },
]