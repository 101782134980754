// extracted by mini-css-extract-plugin
export var blog = "styles-module--blog--1W606";
export var heading = "styles-module--heading--2jUQB";
export var wrapper = "styles-module--wrapper--ltR_D";
export var switched = "styles-module--switched--2AcKE";
export var items = "styles-module--items--1w_N_";
export var item = "styles-module--item--RUACi";
export var calendar = "styles-module--calendar--1Sbkt";
export var calendarHeading = "styles-module--calendarHeading--2mS5q";
export var archive = "styles-module--archive--35efG";
export var blogItem = "styles-module--blog-item--3zOr0";
export var scaled = "styles-module--scaled--2xmGv";