// extracted by mini-css-extract-plugin
export var root = "index-module--root--pE2Mc";
export var header = "index-module--header--1DwT7";
export var actions = "index-module--actions--C35E6";
export var back = "index-module--back--3oLhX";
export var backCaption = "index-module--backCaption--o0uNl";
export var nav = "index-module--nav--1bLLz";
export var pagination = "index-module--pagination--1U4fk";
export var pageLink = "index-module--pageLink--7GqTj";
export var pageItem = "index-module--pageItem--2eYCP";
export var selected = "index-module--selected--16c7d";
export var nextPage = "index-module--nextPage--1D-Ew";
export var prevPage = "index-module--prevPage--3cHSe";
export var disabled = "index-module--disabled--3DoBt";
export var notFoundText = "index-module--notFoundText--1QMgB";
export var captionBack = "index-module--captionBack--3cwJz";
export var search = "index-module--search--24Pwf";
export var posts = "index-module--posts--295uP";
export var wrapper = "index-module--wrapper--3dskW";
export var post = "index-module--post--2OLw7";
export var footer = "index-module--footer--DJOeu";